$(function(){
  var stuckClass = 'is-stuck';
  var $stickyTopElements = $('.sticky-top');
  var $stickyBottomElements = $('.sticky-bottom');
  var $mainLogo = $('.main-logo');
  var $navbarCollapse = $('#mainNavigtion');
  var debounce = null;

  var determineSticky = function() {
    $stickyTopElements.each(function(i, el) {
      var $el = $(el);
      var stickPoint = parseInt($el.css('top'), 10);
      var currTop = ($(window).scrollTop() || $("body").scrollTop()) ;
      var isStuck = currTop <= stickPoint;


      $el.toggleClass(stuckClass, !isStuck);
      $navbarCollapse.toggleClass('top-gb-70', isStuck);
      $mainLogo.toggleClass('navbar-brand-img', isStuck);

    });

    $stickyBottomElements.each(function(i, el) {
      var $el = $(el);
      var stickPoint = parseInt($el.css('bottom'), 10);
      var currBottom = el.getBoundingClientRect().bottom;
      var isStuck = currBottom + stickPoint >= window.innerHeight;

      $el.toggleClass(stuckClass, isStuck);
    });
  };

  //run immediately
  determineSticky();


  //Run when the browser is resized or scrolled
  //Uncomment below to run less frequently with a debounce
  var debounce = null;
  $(window).on('resize scroll', function() {
    // clearTimeout(debounce);
    // debounce = setTimeout(determineSticky, 100);
    determineSticky();
  });

});