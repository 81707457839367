/* jslint browser: true, nomen: true */
/* global $ */

'use strict';

$(function () {
    
    (new UIBindings).bindSlickCarousels();
    (new UIBindings).headerPosition();
    (new UIBindings).bindSharing();
    (new UIBindings).bindMagnificPopup();
    (new UIBindings).bindAdjustFloats();
    (new UIBindings).bindOffCanvas();
    (new UIBindings).bindScrollDown();
    (new UIBindings).bindSelectize();
    (new UIBindings).bindSearch();
    (new UIBindings).bindEvents();
    (new Forms($('form'))).addValidators().validate().bindEvents();
    (new SVGMap).bindEvents();
    (new ExternalLinkHandler).addTargetAttribute($('body'));
    (new StickyFooter($('#container'), $('#footer'))).init().bindOnResize().bindOnLoad();
    (new PageTimer).logToConsole();
    
});

var grecaptcha;

$.fn.exists = function () { return $(this).length > 0; };

function SVGMap () {
    var svg = document.querySelector("#africa-map"),
    svgMap = $('#africa-map'),
    svgParent = svgMap.parent(),
    mapItems = $('[data-svg-map-item]'),
    paths,
    elements = [];
    
    if (svg && svgMap.length > 0) {
        
        paths = svg.querySelectorAll('path');
        
        svg.addEventListener('load', function () {
            mapItems.each(function () {
                var listItem = $(this);
                var id =   listItem.data('svgMapItem');
                var path = svg.querySelector('#' +id);
                
                
                if (path) {
                    
                    var svgElement = (new SVGElements(path));
                    elements[id] = svgElement;
                    svgElement.addClass('pop-up');
                    
                    (new Popovers(listItem, path));
                }
            });
        });
    }
    
    this.bindEvents = function() {
        
        mapItems.on('mouseover', function(){
            var path = $(this).data('svgMapItem');
            
            var current = elements[path];
            
            for (var key in elements) {
                elements[key].removeClass('active').addClass('pop-up');
            }
            
            if (current) {
                current.addClass('active').removeClass('pop-up');
            }
            
            return false;
        }).on('mouseout', function () {
            var path = $(this).data('svgMapItem');
            var current = elements[path];
            
            current.addClass('pop-up').removeClass('active');
        });
        
        
        $(paths).on('mouseover', function(){
            var id = $(this).attr('id');
            mapItems.removeClass('active').filter(function(){
                return $(this).data('svgMapItem') === id;
            }).addClass('active');
        }).on('mouseout', function(){
            var id = $(this).attr('id');
            mapItems.filter(function(){
                return $(this).data('svgMapItem') === id;
            }).removeClass('active');
        });
        
        $(document).on('click','[data-dismiss]', function (evt) {
            var id = $(this).data('dismiss');
            svgMap.find('#' +id).popover('hide');
        })
        
    };
    
    function Popovers(listItem, mapItem) {
        
        
        var id = listItem.data('svgMapItem');
        var content =  $('[data-map-content="'+ id +'"]').html();
        
        var options = {
            content: function () {
                return content;
            },
            html: true,
            placement: 'top',
            container: '[data-map]',
            trigger: 'focus'
        };
        
        
        listItem.on('click', function (evt) {
            if (svgParent.is(':visible')) {
                
                $(mapItem).trigger('click');
                return false;
            }
        });
        
        $(mapItem)
        .popover(options)
        .on('inserted.bs.popover', function () {
            $(document).find('[data-magnific-inline]').magnificPopup({
                type: 'inline',
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-slide-bottom'
            });
        })
        .on('click', function (evt) {
            $(paths).popover('hide');
            $(evt.target).popover('toggle');
        });
    }
}

function SVGElements(el) {
    
    var self = this;
    
    
    this.hasClass = function (name) {
        return new RegExp('(\\s|^)' + name + '(\\s|$)').test(get());
    };
    
    this.addClass = function (name) {
        !self.hasClass(name) && el.setAttribute('class', (get() && get() + ' ') + name);
        return this;
    };
    
    this.removeClass = function (name) {
        var news = get().replace(new RegExp('(\\s|^)' + name + '(\\s|$)', 'g'), '$2');
        self.hasClass(name) && el.setAttribute('class', news);
        return this;
    };
    
    this.toggle = function (name) {
        (self.hasClass(name) ? self.removeClass : self.addClass)(name);
    };
    
    function get() {
        return el.getAttribute('class') ? el.getAttribute('class') : '';
    }
}

function PageTimer() {
    var self = this;
    this.getLoadTime = function () {
        var now = new Date().getTime();
        // Get the performance object
        window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
        var timing = performance.timing || {};
        
        var ms = now - timing.navigationStart;
        
        return Math.round(ms / 10) / 100;
    };
    
    this.logToConsole = function () {
        $(window).on('load', function () {
            console && console.info && console.info("Loaded in " + self.getLoadTime() + 's');
        });
    };
    
    this.append = function (holder) {
        $(window).on('load', function () {
            holder.text(' | LT: ' + self.getLoadTime() + 's');
        });
    }
}

function StickyFooter($wrap, $footer) {
    var $window = $(window);
    
    this.updateWrapperCSS = function () {
        var footerHeight = $footer.outerHeight();
        
        $wrap.css({
            marginBottom: -1 * footerHeight,
            paddingBottom: footerHeight
        });
        
    };
    
    this.bindOnResize = function () {
        $window.on('resize', this.updateWrapperCSS);
        
        return this;
    };
    
    this.bindOnLoad = function () {
        $window.on('load', this.updateWrapperCSS);
        
        return this;
    };
    
    this.init = function () {
        this.updateWrapperCSS();
        
        $footer.removeClass('page-loading');
        
        return this;
    };
}

function Forms(selector) {
    var forms = $(selector);
    
    this.bindEvents = function () {
        forms.find('[data-date-picker-range]').each(function () {
            $(this).datepicker({
                format: 'dd/mm/yyyy',
                autoclose: true,
                inputs: $(this).find('[data-date-picker]'),
                startDate: new Date(),
                templates: {
                    leftArrow: '<i class="fa fa-angle-left"></i>',
                    rightArrow: '<i class="fa fa-angle-right"></i>'
                }
            });
        });
        
        forms.find('[name="page-url"]').val(window.location.href);
        
        return this;
    };
    
    this.addRecaptcha = function (element) {
        var id = $(element).attr('id');
        var validationElement = $(element).closest('[data-parsley-recaptcha]');
        var form = $(element).closest('form');
        
        if (id) {
            
            grecaptcha.render(id, {
                'sitekey' : $(element).data('sitekey'),
                'callback' : function(){
                    validationElement.data('parsleyRecaptcha', 1);
                    form.parsley().validate();
                }
            });
        }
        return this;
    };
    
    this.addValidators = function () {
        
        Parsley.addValidator('recaptcha', {
            requirementType: 'integer',
            validate: function (value, requirement, instance) {
                return $(instance.$element).data('parsleyRecaptcha') === 1;
            }
        });
        
        
        Parsley.addValidator('dropdown', {
            requirementType: 'integer',
            validate: function (value, requirement, instance) {
                var element = $(instance.$element),
                fieldName = element.data('fieldName'),
                fields = $('[name="' + fieldName + '"]').filter(function () {
                    return $.trim(this.value) === ""; // remove the $.trim if whitespace is counted as filled
                });
                
                return !fields.exists();
            }
        });
        
        Parsley.addValidator('honeypot', {
            requirementType: 'string',
            validate: function (value, requirement, instance) {
                return value === requirement;
            }
        });
        
        return this;
    };
    
    this.validate = function () {
        if (forms.exists()) {
            forms.parsley({
                inputs: Parsley.options.inputs + ', [data-parsley-recaptcha], [data-parsley-honeypot], [data-parsley-dropdown]:visible',
                errorClass: 'has-error'
            });
        }
        
        return this;
    };
}

// Google recaptcha callback function, added as a data attribute on the element
function gRecaptcha(response) {
    var recaptchaElement = $('[name="g-recaptcha-response"]').filter(function () {
        return this.value == response;
    });
    
    if (recaptchaElement.exists()) {
        recaptchaElement.closest('[data-parsley-recaptcha]').data('parsleyRecaptcha', 1);
        recaptchaElement.closest('form').parsley().validate();
    }
}

function ExternalLinkHandler() {
    var self = this;
    var hostname = document.location.hostname;
    
    this.matchInternalLink = [new RegExp("^http:\/\/(.*?)" + hostname)];
    
    this.addTargetAttribute = function (context) {
        
        var links = $(context).find('a').filter('[href^="http://"],[href^="https://"]');
        
        $.each(links, function () {
            var anchor = $(this);
            var href = anchor.attr('href');
            
            if (!(href.indexOf(document.domain) > -1 || href.indexOf(':') === -1)) {
                anchor.attr('target', '_blank').addClass('external_link');
            }
        });
    };
}

function UIBindings() {
    var $window = $(window);
    
    
    this.bindEvents = function(){
        $window.on('load', function(){
            $('[data-scroll-down]').addClass('active');
        });
        
        
        $('.article,.content-dialog').on('mouseover', 'img:not(.media-object)', function(evt){
            var img =  $(this);
            var imgAlt =  img.attr('alt');
            var hasAlt = img.hasClass('alt-added');
            var imgWidth = img.width();
            var imgOffset = img.offset();
            
            console.log(img.width(), img.attr('alt'), img.offset());
            if (imgAlt && !hasAlt) {
                img.after(function(){
                    return '<span class="caption" style="width: 100%;"><b class="caption-body" style="width: '+ imgWidth +'px;">' + img.attr('alt') + '<b></span>';
                })
                img.addClass('alt-added') ;
            } else if (hasAlt) {
                img.find('~ .caption').show();
            }
        }).on('mouseout','img', function(){
            $(this).find('~ .caption').hide();
        }).find('img').each(function(){
            $(this).parent('p').addClass('img-center');
            
        })
    };
    
    this.bindTooltips = function () {
        $('[data-toggle="tooltip"]').tooltip();
    };
    
    this.bindPopovers = function () {
        $('[data-toggle="popover"]').popover();
    };
    
    this.bindOffCanvas = function () {
        var $body = $('body');
        $('[data-toggle="offcanvas"]').click(function (evt) {
            var target = $(this).data('target');
            $(target).toggleClass('active');
            $body.toggleClass('modal-open');
        });
    };
    
    this.bindSlickCarousels = function () {
        
        $.fn.carouselResize = function () {
            var carousel = $(this);
            $(window).on('resize', function () {
                if  ($(this).width() > 600 && !carousel.hasClass('slick-slider')) {
                    carousel.slick('reinit').on('reInit', function () {
                        console.log('reInit');
                    });
                }
            })
        };
        
        var bLazy = new Blazy();
        
        
        $('[data-slick-carousel-default]').slick({
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            autoplay: false
        });
        
        $('[data-slick-carousel-autoplay]').slick({
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            fade: true,
            autoplaySpeed: 6000,
            prevArrow: '[data-slick-carousel-autoplay-prev]',
            nextArrow: '[data-slick-carousel-autoplay-next]',
        }).on('afterChange', function(){
            bLazy.revalidate();
        });
        
        $('[data-slick-carousel-autoplay-quotes]').slick({
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: false,
            autoplay: true,
            autoplaySpeed: 8000,
            slide: '[data-slick-carousel-autoplay-quotes] > .slide',
            prevArrow: '[data-slick-quotes-autoplay-prev]',
            nextArrow: '[data-slick-quotes-autoplay-next]',
        });
        
        var carouselListing = $('[data-slick-carousel-listing]');
        
        carouselListing.each(function () {
            var carousel = $(this);
            var next = carousel.find('.next');
            var prev = carousel.find('.prev');
            
            carousel.slick({
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                autoplay: false,
                speed: 250,
                slide: '[data-slick-carousel-listing] > .slide',
                prevArrow: prev,
                nextArrow: next,
                infinite: false,
                responsive: [
                    
                    {
                        breakpoint: 600,
                        settings: "unslick"
                    }
                ]
                
            }).carouselResize();
        });
        
    };
    
    this.bindSharing = function () {
        $("[data-share-default]").each(function () {
            var shareHandler = new ShareHandler($(this));
            shareHandler.appendFacebook();
            shareHandler.appendTwitter();
            shareHandler.appendGoogle();
            shareHandler.appendLinkedIn();
        });
    };
    
    this.bindMagnificPopup = function () {
        $('[data-magnific="gallery"]').magnificPopup({
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: true,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                tCounter: '%curr% of %total%',
                preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
            },
            zoom: {
                enabled: true,
                duration: 500 // don't foget to change the duration also in CSS
                
            }
        });
        
        $('[data-magnific-inline]').magnificPopup({
            type: 'inline',
            fixedContentPos: true,
            fixedBgPos: true,
            overflowY: 'auto',
            closeBtnInside: true,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: 'my-mfp-slide-bottom mfp-inline'
        });

        if($('[href="#mfp-covid"]').length > 0) 
        {

            $('[href="#mfp-covid"]').magnificPopup('open');
        }

        
        $('[data-magnific-iframe]').magnificPopup({
            disableOn: 576,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: true,
            closeOnContentClick: false,
            closeBtnInside: true,
            fixedBgPos: true
        });
        
        $('[data-magnific-form]').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#name',
            mainClass: 'form-dialog',
            modal: true,
            overflowY: 'scroll',
            fixedContentPos: true,
            closeOnContentClick: false,
            closeBtnInside: true,
            
            // When elemened is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function () {
                    if ($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = '#name';
                    }
                }
            }
        });
        
        $('[data-magnific-ajax-form], .enquire-button').magnificPopup({
            type: 'ajax',
            alignTop: true,
            overflowY: 'scroll',
            modal: true,
            disableOn: 576,
            mainClass: 'form-dialog',
            closeOnContentClick: false,
            closeBtnInside: true,
            fixedContentPos: true,
            callbacks: {
                ajaxContentAdded: function () {
                    // Ajax content is loaded and appended to DOM
                    var form = $(this.content).find('.js-form-validate');
                    var captcha = form.find('[data-recaptcha]');
                    (new Forms(form)).validate().addRecaptcha(captcha).bindEvents();
                    (new UIBindings).bindSelectize();
                }
            }
        });
        
        $('[data-magnific-ajax]').magnificPopup({
            type: 'ajax',
            alignTop: true,
            overflowY: 'scroll',
            closeOnContentClick: false,
            closeBtnInside: true
        });
        
        $(document).on('click', '[data-magnific-dismiss]', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });
    };
    
    this.bindScrollDown = function () {
        var offset = 0;
        
        $('[data-scroll-down]').on('click', function (evt) {
            var evtTarget = $(this);
            
            var target = evtTarget.data('scrollDown') || evtTarget.attr('data-scroll-down');
            var $offset = evtTarget.data('offset') || evtTarget.attr('data-offset');
            var _offset = $offset ?  $offset : offset;
            
            scrollToElement(target, {offset: _offset});
        })
    };
    
    this.headerPosition = function () {
        
        window.onscroll = function() {
            headerPosition()  
        };

        var element = document.querySelector(".navbar-fixed-top");
        var endOfDocumentTop = 50;
        var size = 0;
        function headerPosition () {
            
            var scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            var classes = 'in';
            if (size == 0 && scroll > endOfDocumentTop) 
            {
                element.classList.add(classes)
                size = 1;
            } 
            else if(size == 1 && scroll <= endOfDocumentTop) 
            {
                element.classList.remove(classes)
                size = 0;
            }
        }
    };
    
    this.bindAdjustFloats = function() {
        $('.img-left').each(function(){
            var imageWrapper = $(this);
            var adjust = imageWrapper.find(' + .img-right');
            var image = imageWrapper.find('img:eq(0)');
            var imageWidth = image.attr('width');
            
            
            console.log((imageWidth > 479 || adjust.length), imageWidth);
            
            if (!adjust.length) {
                $(this).addClass(imageWidth > 479 ? 'img-adj-center' : 'img-adj');
            }
        });
        
        $('.img-right').each(function(){
            var adjust = $(this).prev('.img-left');
            
            if (!adjust.length) {
                $(this).addClass('img-adj');
            }
        });
        
        $('br + .enquire-button').prev('br').before( '<span class="clearfix"></span>' );
    };
    
    this.bindSelectize = function () {
        $('[data-selectize]').selectize();
    };
    
    this.bindSearch = function () {
        var submitIcon = $('.searchbox-icon');
        var inputBox = $('.searchbox-input');
        var searchBox = $('.searchbox');
        var isOpen = false;
        
        submitIcon.click(function(){
            if(isOpen == false){
                searchBox.addClass('searchbox-open');
                inputBox.focus();
                isOpen = true;
            } else {
                searchBox.removeClass('searchbox-open');
                inputBox.focusout();
                isOpen = false;
            }
        });
        
        inputBox.on('keyup change', function () {
            var $input = $(this);
            var inputVal = $input.val();
            inputVal = $.trim(inputVal).length;
            if( inputVal !== 0){
                submitIcon.css('display','none');
            } else {
                $input.val('');
                submitIcon.css('display','block');
            }
        });
        
        submitIcon.mouseup(function(){
            return false;
        });
        
        searchBox.mouseup(function(){
            return false;
        });
        
        $(document).mouseup(function(){
            if(isOpen == true){
                submitIcon.css('display','block');
                submitIcon.click();
            }
        });
        
    }
    
}

function ShareHandler($container) {
    //var query = $.param(params);
    
    var definedProps = {
        url: $container.data('url'),
        text: $container.data('text')
    };
    
    var buildHttpQuery = function (data) {
        var copy = {};
        
        $.each(data, function (k, v) {
            if (v) {
                copy[k] = v;
            }
        });
        
        return $.param(copy);
    };
    
    var openPopup = function (shareWindow, conf) {
        var top = Math.round((screen.height - conf.height)/2);
        var left = Math.round((screen.width - conf.width)/2);
        
        shareWindow.location.href = conf.url;
        shareWindow.focus();
        shareWindow.resizeTo(conf.width, conf.height);
        
        shareWindow.moveTo(left, top);
        
    };
    
    var popups = {
        'google-plus': function (opt) {
            return {
                url: "https://plus.google.com/share?" + buildHttpQuery({
                hl: opt.lang,
                url: opt.url
            })
        };
    },
    facebook: function (opt) {
        return {
            url: "http://www.facebook.com/sharer/sharer.php?" + buildHttpQuery({
            u: opt.url,
            t: opt.text
        }),
        width: 900,
        height: 500
    };
},
twitter: function (opt) {
    return {
        url: "https://twitter.com/intent/tweet?" + buildHttpQuery({
        text: opt.text,
        url: opt.url,
        via: opt.via
    }),
    
    width: 650,
    height: 360
};
},
linkedin: function (opt) {
    return {
        url: 'https://www.linkedin.com/cws/share?url=' + buildHttpQuery({
        url: opt.url,
        isFramed: 'true'
    }),
    width: 550,
    height: 550
};
},
pinterest: function (opt) {
    return {
        url: 'http://pinterest.com/pin/create/button/?url=' + buildHttpQuery({
        url: opt.url,
        description: opt.text,
        media: opt.image
    }),
    width: 700,
    height: 300
};
}
};


var self = this;

this.createButton = function (platform) {
    var button = $('<a class="share-btn share-btn-'+ platform +'"><i class="fa fa-' + platform + '-square fa-2x" ></i></a>');
    
    button.css({cursor: 'pointer'});
    
    button.on('click', function (e) {
        e.preventDefault();
        self.share(platform);
    });
    
    $container.append(button);
    
    return button;
};

this.appendTwitter = function () {
    $container.append(self.createButton('twitter'));
};


var sharePage = function (shareWindow, platform, url, $page) {
    var shareProps = $.extend({}, definedProps);
    
    shareProps.platform = platform;
    
    if (!shareProps.text) {
        shareProps.text = $page.find('meta').filter('[property="article-title"]').attr('content');
    }
    if (!shareProps.via) {
        shareProps.via = $page.find('#twitterHandle').attr('content');
    }
    if (!shareProps.lang) {
        shareProps.lang = $page.find('html').attr('lang');
    }
    if (!shareProps.image) {
        shareProps.image = $page.find('meta').filter('[property="og:image"]').attr('content');
    }
    
    var text = shareProps.text;
    
    if (platform == 'twitter') {
        var startLen = 23 + 3; // twitter short URL length + 3
        if (shareProps.via) {
            startLen += (' via @' + shareProps.via).length;
        }
        if (text.length + startLen > 140) {
            var diff = (text.length + startLen) - 140;
            text = text.substr(0, text.length - diff) + '…';
        }
    }
    
    var popupConf = popups[platform]({
        url: url,
        text: text,
        via: shareProps.via
    });
    
    openPopup(shareWindow, popupConf);
};

this.share = function (platform) {
    var url = definedProps.url ? definedProps.url : window.location.href;
    
    var shareWindow = window.open(
        "",
        "share" + platform,
        "toolbar=0, status=0, width=" + 50 + ", height=" + 50 + ", top=" + 0 + ", left=" + 0
        );
        
        if (window.location.href !== url) {
            // show loader
            var shareLoader = $('<div id="shareLoader"></div>');
            var fade = $('<div style="position: fixed; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5);"></div>');
            var spinner = $('<i class="fa fa-cog fa-spin" style="color: #fff"></i>');
            spinner.css({position: 'fixed', top: '50%', left: '50%' });
            fade.append(spinner);
            shareLoader.append(fade);
            
            $('body').append(shareLoader);
            
            $.ajax(url, {
                async:    true,
                success: function (data) {
                    shareLoader.remove();
                    sharePage(shareWindow, platform, url, $('<root/>').append(data));
                },
                error: function () {
                    shareLoader.remove();
                    alert('Failed to load URL: ' + url);
                }
            });
        } else {
            sharePage(shareWindow, platform, url, $(document));
        }
        
    };
    
    this.appendFacebook = function () {
        $container.append(self.createButton('facebook'));
    };
    
    this.appendGoogle = function () {
        $container.append(self.createButton('google-plus'));
    };
    
    // TODO test
    this.appendLinkedIn = function () {
        $container.append(self.createButton('linkedin'));
    };
    
    // TODO test / enable
    this.appendPinterest = function () {
        $container.append(self.createButton('pinterest'));
    };
}

function scrollToElement(target, options) {
    
    var settings = $.extend({
        speed: 750,
        offset: 50
    }, options);
    
    var targetOffset = $(target).offset().top - settings.offset;
    
    $('html,body').animate({scrollTop: targetOffset},  settings.speed, function () {});
}